.parent-division{
    display : flex;
    flex-direction: column;
    gap : 30px;
    background-color: #ffffff;
    color : black;
    width: 600px;
    padding-top : 40px;
    padding-left : 20px;
    padding-bottom : 40px;
    padding-right: 20px;
}

#label-title {
    font-weight: 700;
    font-size: 32px;
}

.greet-class{
    display : flex;
    flex-direction: column;
    gap : 10px;
    padding-top: 10px;
}

#verify-button{
    background-color: white;
    display: flex;
}

#verify-button-id{
    background-color: rgb(35, 35, 35);
    color: white;
    display: flex;
    padding : 15px;
    font-weight : 400;
    font-size: 18px;
    border-radius: 6px;
}

#greet-out{
    display: flex;
    flex-direction: column;
    gap : 10px;
}

#the-last-tagline{
    color: rgb(27, 34, 58);
    text-decoration: 0.5px underline;
    text-underline-offset: 2px;
}

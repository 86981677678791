.form-input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input-label {
    font-size: 16px;
    line-height: 100%;
    font-weight: 500;
    color: #B7BFC7;
}

.form-input-wrap {
    height: 44px;
    border: 1px solid #606060;
    border-radius: 8px;
}

.input-username-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.input-username-wrap span {
    width: 103px;
    height: 100%;
    border-right: 1px solid #606060;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B7BFC7;
}

.input-username, .input-email, .input-password {
    flex-grow: 1;
    height: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
    color: #B7BFC7;
    padding: 10px 20px;
}

.input-email {
    border: 1px solid #606060;
}

.input-password-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-password-wrap div {
    flex-grow: 0;
    height: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #606060;
}

.input-usage-consent-container {
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.input-usage-consent-checkbox {
    cursor: pointer;
    color : #ffffff;
    /* height: 0; */
    /* width: 0; */
}

.input-usage-consent-checkbox-custom {
    background-color: transparent;
    border: 1px solid #5E5C5C;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
}


/* Custom checkbox css */
.checkbox-wrapper-4 {
    display: flex;
    flex-direction: row;
}
.checkbox-wrapper-4 * {
    box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 0px 0px;
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
}
/* .checkbox-wrapper-4 .cbx:not(:last-child) {
    /* margin-right: 6px;
} */

/*.checkbox-wrapper-4 .cbx:hover {
    /* background: rgba(0,119,255,0.06); */

.checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #606060;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0,16,75,0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 2px;
    left: 1px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 200;
    color: #FFFFFF;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #07f;
}
.checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
    background: #07f;
    border-color: #07f;
    animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}
@media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
        width: 100%;
        display: inline-block;
    }
}
@-moz-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}
@-webkit-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}
@-o-keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}
@keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 1046px;
    height: 557px;
    border: 1px solid #343B4F;
    border-radius: 12px;
    background-color: #060E17;
    /* align-items: center; */
    /* justify-content: center; */
}

.containerCol1 {
    display: flex;
    flex-direction: column;
    width: 676px;
    border-right: 1px solid #343B4F;
    align-items: center;
    justify-content: center;
}

.graph1Row1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.div1 {
    display: flex;
    flex-direction: column;
}

.div3 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.legend1, .legend2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.graph1Row2 {
    width: 594px;
    height: 394px;
}
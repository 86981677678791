.container {
    width: 260px;
    flex-shrink: 0;
    top: 68px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: stretch;
    box-sizing: border-box;
}

.links {
    padding: 8px 16px;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    border-radius: 6px;
    height: 40px;
}

.links:hover {
    background-color: #111927;
}

.icons {
    height: 24px;
    width: 24px;
}
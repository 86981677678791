.ParentList{
    display: flex;
    width : 1490px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-image: 
    linear-gradient(
        135deg, 
        rgba(27, 27, 27, 1), 
        rgba(67, 153, 255, 0.2), 
        rgba(67, 153, 255, 0.3), 
        rgba(67, 153, 255, 0.2), 
        rgba(67, 153, 255, 0)
    );
    flex-wrap: wrap;
    gap : 334px; 
}

.ParentListLeft {
    display: flex;
    gap : 15px;
}

.ImgClass{
    display: flex;
    padding-left: 90px;
    justify-content: center;
}

.ProfilePic {
    height: 74px;
    width: 74px;
}

.TitleClass{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Title{
    font-size: 20px;
    font-weight: 600;
}

.Role{
    font-weight: 500;
    font-size: 12px;
    color : #727a85;
}

.TagDivList{
    padding-top: 7px;
}

.ParentListRight{
    display: flex;
    gap : 81px;
    justify-content: center;
    align-items: center;
}
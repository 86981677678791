/* Begin */

.header-container {
    width: 100%;
    height: 68px;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #000000;
}

.header-container .right-hand-side {
    display: inline-flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 40px;
}

.login-header {
    background-color: #161B22;
}

.header-inline {
    /* display: inline-block; */
    text-decoration: none;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}

#app-logo-img {
    left: 40px; 
}

#app-logo-text {
    left: 81px;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    
    background: -webkit-linear-gradient(0deg, #C632C9 45%, #58A6FF 56%);
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header-navigation-links {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    color: #FDF6FC;
}

#header-homepage-link {
    left: 40px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
}

#header-products-link {
    left: 234px;
}

#header-categories-link {
    left: 329px;
}

#header-about-link {
    left: 440px;
}

#header-analytics-link {
    left: 517px;
}

#header-searchbar-form {
    left: 50%;
    transform: translate(-50%, -50%);
}

#header-searchbar-input {
    padding-left: 29px;
    width: 583px;
    height: 32px;
    border: 0px solid transparent;
    border-radius: 16px;
    background-color: #363738;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

#header-searchbar-input::placeholder {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

#header-create-drop-button {
    right: 64px;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    padding: 6px 20px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500px;
    line-height: 20px;
    border: 1px solid rgba(240, 246, 252, 10%);
    border-radius: 6px;
    background-image: linear-gradient(90deg, rgba(9, 105, 218, 100%), rgba(5, 56, 116, 100%));
}

#header-create-drop-button, .login {
    right: 116px;
    border: 1px solid rgba(27, 27, 27, 100%);
    border-radius: 6px;
    background-image: linear-gradient(90deg, rgba(198, 50, 201, 100%), rgba(5, 56, 116, 100%));
}

#header-login-link {
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: none;
    color: #FFFFFF;
}

#header-promote-drop-button {
    /* right: 220px; */
    display: inline-flex;
    align-content: center;
    justify-content: center;
    padding: 6px 20px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500px;
    line-height: 20px;
    border: 1px solid #0960DA;
    border-radius: 6px;
    /* background-image: linear-gradient(90deg, rgba(9, 105, 218, 100%), rgba(31, 158, 152, 50%), rgba(15, 110, 255, 0%)); */
}

#header-notification-button {
    right: 65px;
    margin-top: -2px;
}

#header-user-profile-pic {
    right: 24px;
    height: 28px;
    width: 28px;
    object-fit: cover;
    border-radius: 50%;
}

/* For mobile screens */
@media screen and (max-width: 430px) {
    .right-hand-side {
        position: static;
        display: none;
        width: 0;
        overflow: hidden;
    }

    #app-logo-img {
        left: 16px; 
    }

    #app-logo-text {
        left: 60px;
    }
}
.fundParentClass{
    display: flex;
    flex-direction: column;
    width: 250px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    border: 0.6px solid #343B4F;
    gap: 65px;
    border-radius: 8px;
    background: #060E17;
}

.balanceClass{
    display: flex;
    flex-direction: column;
    gap:55px;
}

.balanceTitle{
    font-weight: 500;
    font-size: 12px;
    color: #AEB9E1;
}

.balanceMoney{
    font-weight: 600;
    font-size: 24px;
    color: white;
    fill: #010409;
}

.withdrawBtnClass{
    display: flex;
    justify-content: center;
    align-items: center;
}

.withdrawBtn{
    border: none;
    background : #053874;
    color: white;
    font-weight: 500;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 76.5px;
    padding-right: 76.5px;
    border-radius: 4px;
}

.earningClass{
    display: flex;
    flex-direction: column;
    gap:55px;
}

.earningTitle{
    font-weight: 500;
    font-size: 12px;
    color: #AEB9E1;
}

.earningMoney{
    font-weight: 600;
    font-size: 24px;
    color: white;
    fill: #010409;
    padding-bottom: 104px;
}
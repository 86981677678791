/* Begin */

/* body {
    background-color: #001118;
} */

.the-most-epic-div-container {
    width: 100vw;
    background-color: #09da8f;
    box-sizing: border-box;
}

.landing-page-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #0d1118;
    gap: 100px;
    padding: 100px 100px;
    overflow: visible;
}

.inline {
    display: inline-block;
    align-self: center;
}

.inline-flex {
    display: inline-flex;
    align-self: center;
}

.tagline-1 {
    color: #efefef;
    font-size: 53px;
    line-height: 72px;
    text-align: center;
    font-weight: 400;
}

.tagline-1-gradient-1 {
    background-clip: "initial";
    background: linear-gradient(to right, #0969da, #09da8f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.tagline-1-gradient-2 {
    background-clip: "initial";
    background: linear-gradient(to right, #0969da, #bd09da);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.globe {
    width: 1063px;
    height: 1063px;
    align-self: center;
    margin-top: -150px;
    margin-bottom: -150px;
}

.tagline-2 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    line-height: 150%;
    color: white;
}

.tagline-2-gradient-1 {
    font-weight: 700;
    background-clip: "initial";
    background: linear-gradient(to right, #1577d2, #9615d2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tagline-2-gradient-2 {
    font-weight: 600;
    background-clip: "initial";
    background: linear-gradient(to right, #0f6eff, #ca2f2f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.companies {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #6e7681;
    width: 100%;
}

.companies-2 {
    flex-basis: 1076px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    gap: 10px;
    flex-shrink: 1;
    flex-wrap: wrap;
}

.quote-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 55px;
}

.quote-img {
    width: 457px;
    height: 514px;
}

.quote-body-wrap {
    font-size: 36px;
    font-weight: 400;
    line-height: 150%;

    text-align: left;
    vertical-align: middle;
    align-self: center;

    display: flex;
    flex-direction: column;
    gap: 15px;
}

.quote-body {
    margin: 0;
    background-clip: "initial";
    background: linear-gradient(to right, #d9d9d9, #298cf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quote-body-text-1,
.quote-body-text-2,
.quote-body-text-4 {
    display: inline-block;
    font-weight: 700;
}

.quote-body-text-3 {
    display: inline-block;
    font-weight: 800;
}

.quote-source-pre,
.quote-source {
    color: white;
}

.quote-source-wrap {
    margin: 0;
}

.quote-source {
    font-style: italic;
    font-weight: 300;
}

.about-1 {
    text-align: center;
}

.about-1-1 {
    font-weight: 700;
    font-size: 96px;
    line-height: 150%;

    background-clip: "initial";
    background: linear-gradient(
        to right,
        rgba(21, 119, 210, 100%),
        rgba(109, 5, 61, 84%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-1-2 {
    font-weight: 400;
    font-size: 24px;
    color: #f5f7f9;
}

.about-1-3 {
    font-weight: 800;
    font-size: 32px;

    background-clip: "initial";
    background: linear-gradient(
        to right,
        rgba(10, 124, 238, 100%),
        rgba(200, 7, 54, 100%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-2 {
    position: relative;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 200px;
}

.about-2-1 {
    font-weight: 700;
    font-size: 204px;
    line-height: auto;
    color: #ffffff;
}

.about-2-2 {
    font-weight: 700;
    font-size: 48px;
    line-height: auto;
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    left: 54px;
}

.about-2-img-1 {
    position: absolute;
    left: 255px;
    bottom: 3px;
}

.about-2-img-2 {
    position: absolute;
    right: 227px;
    top: 153px;
}

.about-3 {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-3-1 {
    font-size: 40px;
    font-weight: 300;
    text-align: center;

    background-clip: "initial";
    background: linear-gradient(
        to right,
        rgba(15, 110, 255, 100%) 40%,
        rgba(202, 47, 47, 93%) 60%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-3-2 {
    font-weight: 800;
}

.about-3-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    gap: 20px;
    align-self: center;
    /* width: 1476px; */
}

.about-3-3 > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border: 0.2pt solid #222222;
    border-radius: 8px;
    padding: 30px 60px;

    background: linear-gradient(to right, #000000, #1b1b1b);
}

.about-3-3 > .card > .title {
    font-size: 20px;
    font-weight: 600;
    color: #f2e5e5;
    margin: 0;
}

.about-3-3 > .card > .description {
    font-size: 22px;
    font-weight: 200;
    color: #ffffff;
    margin: 0;
}

.about-4 {
    display: flex;
    flex-direction: column;
    gap: 126px;
    align-items: center;
}

.about-4-1 {
    font-size: 40px;
    line-height: 150%;
    font-weight: 600;

    background-clip: "initial";
    background: linear-gradient(
        to right,
        rgba(15, 110, 255, 100%) 40%,
        rgba(202, 47, 47, 93%) 65%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-4-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 100px;
    flex-grow: 0;
    flex-shrink: 0;
}

.about-4-3 .card {
    width: 327px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    /* background-color: #0969DA; */
}

.about-4 .icon-containers {
    display: flex;
    flex-direction: row;
    align-items: start;
}

.about-4-3-icons {
    height: 48px;
    width: 48px;
}

.about-4-3 .card .title {
    font-size: 40px;
    line-height: 150%;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-4-3 .card .description {
    font-size: 24px;
    line-height: 150%;
    font-weight: 200;
}

.faq {
    /* display: flex;
    flex-direction: column;
    gap: 30px;
    width: 1000px;
    flex: 1; */
    width: 100%;
}

.faq-title {
    text-align: center;
    font-weight: 900;
    font-size: 32px;

    background-clip: "initial";
    background: linear-gradient(
        to right,
        rgba(15, 110, 255, 100%) 40%,
        rgba(202, 47, 47, 93%) 65%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer {
    text-align: center;
}

.footer-app {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #9a9da3;
}

.footer-rights {
    font-weight: 200;
    font-size: 10px;
    color: white;
}

/* For mobile screens */
@media screen and (max-width: 460px) {
    .landing-page-container {
        padding: 100px 16px;
        padding-bottom: 0;
        gap: 75px;
        width: 100%;
        /* width: 100vw; */
        align-items: center;
        /* align-items: stretch; */
    }

    .tagline-1,
    .tagline-2 {
        font-size: 20px;
        line-height: 25px;
    }

    /* .tagline-2 {
        font-size: 14px;
        line-height: 150%;
    } */

    .about-1-1 {
        font-size: 40px;
        line-height: 150%;
    }

    .about-1-2 {
        width: 235px;
        display: block;
    }

    .about-1-2,
    .about-1-3 {
        font-size: 12px;
        line-height: 150%;
        color: rgba(245, 247, 249, 66%);
        font-weight: 400;
    }

    .about-1-3 {
        background-clip: none;
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: rgba(245, 247, 249, 66%);
    }

    .about-2-img-1,
    .about-2-img-2 {
        width: 211px;
        height: 83px;
    }

    .about-2-img-2 {
        top: 0;
        left: 0;
    }

    .about-2-img-1 {
        bottom: 0;
        left: initial;
        right: 0;
    }

    .about-2-1 {
        font-size: 128px;
        line-height: 128px;
    }

    .about-2-2 {
        font-size: 36px;
        line-height: 36px;
        position: static;
    }

    .about-2 {
        width: 100vw;
        height: 182px;
        margin: 48px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .about-2 br {
        display: none;
    }

    /* .about-2 span {
        width: 100%;
        text-align: center;
    } */

    .about-4 {
        gap: 20px;
    }

    .about-4-1 {
        font-size: 15px;
        line-height: 150%;
        font-weight: 800;
    }

    .about-4-3 {
        flex-direction: column;
        gap: 48px;
    }

    .about-4-3 .card {
        width: 100%;
        align-items: center;
        gap: 0px;
    }

    .icon-containers img {
        height: 30px;
        width: 30px;
    }

    .about-4-3 .card .title {
        font-size: 20px;
        line-height: 150%;
    }

    .about-4-3 .card .description {
        text-align: center;
        font-size: 18px;
        line-height: 150%;
    }

    .quote-block {
        flex-direction: column;
    }

    .quote-img {
        display: none;
    }

    .quote-body {
        font-size: 18px;
        line-height: 150%;
        text-align: center;
    }

    .quote-source-wrap {
        display: none;
    }

    .about-3-3 .card {
        padding: 20px 13px;
        gap: 8px;
    }

    .about-3-3 .card .title {
        font-size: 14px;
        line-height: 150%;
    }

    .about-3-3 .card .description {
        font-size: 12px;
        line-height: 150%;
    }

    .about-3-1,
    .faq-title {
        font-size: 15px;
        line-height: 150%;
        font-weight: 800;
    }
    .globe {
        width: 100%;
        height: 100%;
        margin-top: -80px;
        margin-bottom: -80px;
    }
}

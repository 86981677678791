.body-type-form-container {
    height : 100vh;
    width : 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.final-form {
    background-color : black;
    display : flex;
    width : 525px;
    flex-direction: column;
    padding-top : 35px;
    padding-left : 34px;
    padding-right : 34px;
    padding-bottom : 35px;
    gap :24px;
    border-radius: 8px;
}

#title-div{
    color : white;
}

#title-linkyo{
    font-size: 20;
    font-weight: 600;
}

#title-desc{
    padding-top : 12px;
    font-size: 14;
    color : #B7BFC7;
}

#form-inputs-component{
    padding-top : 78px;
}

#form-inputs-component{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom : 50px;
}

.Img-about-class{ 
    border: 1px solid #606060;
    padding-left : 24px;
    padding-right : 24px;
    border-radius: 8px;
    padding-bottom: 45px;
}

.upload-img-class{
    display : flex;
    align-items: center;
    gap : 20px;
    padding-top : 24px;
    padding-bottom : 24px;
}

#profile-img{
    height: 58px;
    width: 58px;
}

#upload-button{
    background-color: #000000;
    border: 1px solid #606060;
    color : #B7BFC7;
    font-size: 14px;
    padding : 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
}

.Label-about-class{
    display: flex;
    flex-direction: column;
    gap :10px;
}

#text-wrapper{
    border: 1px solid #606060;
    height: 165px;
    border-radius: 8px;
    padding: 5px;
}

#about-yourself-text{
    box-sizing: border-box;
    background-color: #000000;
    width: 100%;
    padding :5px;
    display: flex;
    color: #B7BFC7;
    border : none;
    align-items: flex-start;
    outline: 0;
    text-align: start;
    height: 100%;
}

::placeholder {
    color: #7b7ea3;
    opacity: 1; /* Firefox */
  }

.finishButton{
    height: 42px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    z-index: 3;

    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;

    /* Gradient Color Borders */
    background: 
        linear-gradient(to right, rgba(9, 105, 218, 100%), #0D1118), 
        linear-gradient(to bottom right, #30363D, #57606A); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 1px solid transparent;
    border-radius: 9px;
}

#label-about{
    color : #B7BFC7;
}


.tableHead{
    display: flex;
    gap : 95px;
    width: 480px;
    background-color: #0A1330;
    padding-top: 14px;
    padding-bottom: 14px;
    font-weight: 500;
    font-size: 10px;
}

.tableStatusCol{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 18px;
    background-color: rgba(5, 193, 104, 20%) ;
    padding-top : 0px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 0px;
    color: #14CA74;
    border-radius: 2px;
    border: 1px solid rgba(5, 193, 104, 50%);
    line-height: 100%;
}

.tableStatusCol::before{
    content : "\00B7";
    padding-right: 4px;
    font-size: 2em;
}

.tableOrdersCol{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tableDateCol{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tableTotalCol{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.body-type-class {
    height: 100vh;
    width : 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    color: #FFFFFF;
    position: absolute;
}


#check-yo-email-container{
    width: 600px;
    display : flex;
    position: relative;
    background-color: #0D1118;
    align-items : center;
    align-content: center;
    flex-direction : column;
    padding-top : 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom : 50px;
    justify-content: center;
    font-family: 'calibri';
}

#email-img{
    height : 60px;
    width : 60px;
}

#text-content{
    text-align: center;
    color : #8e8e8e;
}

#do-underline{
    text-decoration: underline;
}
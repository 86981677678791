/* Begin */

.signup-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    max-width: 591px;
    min-width: 200px;
}

/* For mobile screens */
@media screen and (max-width: 430px) {
    .signup-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        width: 100%;
        max-width: 591px;
        min-width: 200px;
    }

    .signup-form-input {
        display: flex;
        flex-direction: row;
        height: 52px;
        box-sizing: border-box;
        color: white;
        justify-content: baseline;
    
        /* text-align: center; */
    
        /* Gradient Color Borders */
        background: 
            linear-gradient(to right, #161B22, #161B22), 
            linear-gradient(to bottom right, #D2A8FF, #920A0A); 
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        border: 1px solid transparent;
        border-radius: 9px;
    }
    

    .signup-form-input input::placeholder {
        width: 100%;
        font-size: 2px;
        font-weight: 400;
        color: #958B8B;
    }

    .signup-form-input input {
        display: flex;
        padding-left: 33px;
        padding-right: 10px;
        flex-grow: 1;
        background-color: transparent;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #958B8B;
    }
}

.signup-google-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    box-sizing: border-box;
    cursor: pointer;

    color: black;
    width: 400px;
    height: 52px;
    align-self: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 22px;

    /* Gradient Color Borders */
    background: 
        linear-gradient(to right, #FFFFFF, #999999), 
        linear-gradient(to bottom right, #D2A8FF, #920A0A); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 1px solid transparent;
    border-radius: 9px;
}

.signup-google-link > img {
    width: 27px;
    height: 27px;
}

.signup-partition {
    flex-direction: row;
    display: flex;
    margin: 0;
    gap: 1px;
    color: #958B8B;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

hr {
    width: 100%;
    border-color: #606060;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 400px;
    gap: 15px;
    align-self: center;
}

.signup-form-input {
    display: flex;
    flex-direction: row;
    height: 52px;
    box-sizing: border-box;
    color: white;
    justify-content: baseline;

    /* text-align: center; */

    /* Gradient Color Borders */
    background: 
        linear-gradient(to right, #161B22, #161B22), 
        linear-gradient(to bottom right, #D2A8FF, #920A0A); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 1px solid transparent;
    border-radius: 9px;
}

.signup-form-input input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #958B8B;
}

.signup-form-input input:focus {
    outline: none;
}

.signup-form-input div {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 121px;
    flex-shrink: 0;
    border-right: 1px solid #606060;
    font-size: 20px;
}

.signup-form-input input {
    display: flex;
    padding-left: 33px;
    padding-right: 10px;
    flex-grow: 1;
    background-color: transparent;
    border: none;
    /* width: 100%; */
    font-size: 16px;
    font-weight: 400;
    color: #958B8B;
}

.signup-form-submit-button {
    height: 52px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 16px;
    line-height: 22px;

    /* Gradient Color Borders */
    background: 
        linear-gradient(to right, rgba(9, 105, 218, 100%), #0D1118), 
        linear-gradient(to bottom right, #30363D, #57606A); 
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border: 1px solid transparent;
    border-radius: 9px;
}

.signup-form-arrow {
    color: white;
    width: 10px;
    height: 5px;
    rotate: -90deg;
}

/* For mobile screens */
@media screen and (max-width: 430px) {
    .signup-google-link, .signup-form {
        width: 100%;
    }
}
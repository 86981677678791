body {
    background-color: #010409;
}

#create-drop-content {
    padding: 166px 100px;
    display: flex;
    flex-direction: column;
    gap: 0;
    height: 100%;
}

#create-drop-tagline-1 {
    width: 390px;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
}

#create-drop-tagline-2 {
    width: 1042px;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
}

#create-drop-subform-1 {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

#create-drop-subform-1-input {
    width: 699px;
    height: 48px;
    padding-left: 38px;
    background-color: #0d0d0d;
    border: 1px solid rgba(96, 96, 96, 50%);
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
}

#create-drop-subform-1-input::placeholder {
    color: #3c3e5e;
}

#create-drop-subform-1-get-started-button {
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    border: 1px solid #909090;
    border-radius: 6px;
    background-image: linear-gradient(
        110deg,
        rgba(9, 105, 218, 100%),
        #105050,
        #010409
    );
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

#create-drop-form-main-display-container {
    display: none;
    opacity: 0;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 62px;
}

#create-drop-form-main-form-display {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1px;
    background-image: linear-gradient(
        180deg,
        rgba(9, 105, 218, 79%),
        rgba(23, 2, 12, 79%)
    );
    border-radius: 12px;
    width: 878px;
    height: 1258px;
}

#create-drop-form-main-form-display-header {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 32px 30px;
    background-color: #0d0d0d;
    border-radius: 12px 12px 0 0;
    border: 1px solid transparent;
}

#create-drop-form-main-form-display-header span {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: #ffffff;
}

#create-drop-form-main-form-display-body {
    display: flex;
    flex-direction: column;
    gap: 70px;
    background-color: #0d0d0d;
    padding: 35px 90px;
    flex-grow: 1;
}

#create-drop-form-main-form-display-body-sections {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    align-content: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: rgba(255, 255, 255, 40%);
}

#create-drop-form-main-form-display-body-sections .active {
    color: rgba(255, 255, 255, 100%);
}

/* #create-drop-form-main-form-display-body-sections span {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 9px;
} */

.breadcrumbs-navigation {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.breadcrumbs-connector {
    height: 2px;
    background: #9CA3AF;
    flex-grow: 1;
}

.breadcrumbs-navigation-node {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    gap: 25px;
    /* display: inline-block; */
}

.breadcrumbs-navigation-node-label-container {
    position: absolute;
    top: -45px;
    display: flex;
    width: fit-content;
    flex-grow: 0;
    z-index: 1;
    justify-content: center;
}

.breadcrumbs-navigation-node-label {
    position: relative;
    flex-shrink: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: #ffffff;
}

.breadcrumb-outer-circle {
    height: 32px;
    width: 32px;
    border: 2px solid #9ca3af;
    border-radius: 999px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background-color: #0d0d0d;
}

.breadcrumb-inner-circle {
    height: 10px;
    width: 10px;
    background-color: #9ca3af;
    border-radius: 999px;
}

#create-drop-form-main-form-display-body-text {
    font-size: 11px;
    line-height: 16px;
    color: #ffffff;
    width: 699px;
}

.create-drop-form-subforms {
    display: flex;
    flex-direction: column;
    gap: 52px;
}

.create-drop-form-subform-2-fieldset {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border: 0;
    margin: 0;
    padding: 0;
}

.subform-title {
    font-size: 20px;
    line-height: 16px;
}

.subform-description {
    font-size: 14px;
    line-height: 16px;
}

.create-drop-form-subform-2-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.create-drop-form-input-fields-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 699px;
}

.create-drop-form-input-field-labels {
    font-size: 16px;
    font-weight: 500;
    color: #bbd1f6;
}

.create-drop-form-text-inputs {
    box-sizing: border-box;
    width: 699px;
    height: 48px;
    padding-left: 38px;
    background-color: #0d0d0d;
    border: 1px solid rgba(96, 96, 96, 50%);
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
}

.create-drop-form-select-inputs {
    box-sizing: border-box;
    width: 699px;
    height: 48px;
    padding-left: 38px;
    background-color: #0d0d0d;
    border: 1px solid rgba(96, 96, 96, 50%);
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
}

.create-drop-form-text-inputs::placeholder {
    color: #3c3e5e;
}

.create-drop-form-textarea-inputs {
    box-sizing: border-box;
    width: 699px;
    padding-left: 38px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #0d0d0d;
    border: 1px solid rgba(96, 96, 96, 50%);
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    resize: none;
}

.create-drop-form-textarea-inputs::placeholder {
    color: #3c3e5e;
}

.create-drop-form-file-input-containers {
    box-sizing: border-box;
    width: 699px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #0d0d0d;
    border: 1px solid rgba(96, 96, 96, 50%);
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 8px;
}

.create-drop-form-file-inputs {
    display: none;
}

.create-drop-form-file-input-containers img {
    height: 24px;
    width: 24px;
}

.create-drop-form-file-input-containers span {
    font-size: 12px;
    color: #3c3e5e;
}

.create-drop-form-navigation-buttons-container {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.create-drop-form-navigation-next-subform-buttons {
    box-sizing: border-box;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    border: 1px solid #363636;
    border-radius: 6px;
    background-image: linear-gradient(
        110deg,
        rgba(9, 105, 218, 100%),
        #105050,
        #010409
    );
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.create-drop-form-navigation-next-subform-buttons.next {
    flex-grow: 1;
}

#create-drop-form-preview-display-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 1px;
    background-image: linear-gradient(
        180deg,
        rgba(9, 105, 218, 79%),
        rgba(23, 2, 12, 79%)
    );
    border-radius: 12px;
    flex-basis: auto;
    height: 100%;
}

#create-drop-form-preview-header {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 32px 30px;
    background-color: #0d0d0d;
    border-radius: 12px 12px 0 0;
    border: 1px solid transparent;
    justify-content: space-between;
}

#create-drop-form-preview-header span {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: #ffffff;
}

#create-drop-form-submit-button {
    box-sizing: border-box;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 32px;
    border: 1px solid #363636;
    border-radius: 6px;
    background-image: linear-gradient(
        110deg,
        rgba(9, 105, 218, 100%),
        #105050,
        #010409
    );
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

#create-drop-form-preview-body {
    background-color: #0d0d0d;
    /* flex: 1; */
    display: flex;
    justify-content: center;
    padding: 289px 51px;
}

hr.createDrop {
    padding: 0;
    margin: 0;
    border-color: transparent;
}

#create-drop-form-verify-email-button {
    display: block;
    background-color: #0F6EFF;
    border: 1px solid #909090;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.create-drop-form-pin-inputs-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 9px;
}

.create-drop-form-pin-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    background-color: #0D0D0D;
    border: 1px solid #606060;
    border-radius: 8px;
}

#create-drop-form-subform-2 {
    position: relative;
}

.create-drop-form-subform-2-fieldset {
    position: absolute;
}

#create-drop-form-subform-2-fieldset-1 {
    display: none;
    opacity: 0;
}

#create-drop-form-subform-2-fieldset-2 {
    display: none;
    opacity: 0;
}

#create-drop-form-subform-2-fieldset-3 {
    display: none;
    opacity: 0;
}

#create-drop-form-subform-2-fieldset-4 {
    display: none;
    opacity: 0;
}

#create-drop-form-subform-2-fieldset-5 {
    display: none;
    opacity: 0;
}

#create-drop-subform-1-container {
    display: none;
    opacity: 0;
}

.breadcrumb-check {
    height: 18px;
    width: 18px;
    fill: #ffffff;
}

.breadcrumb-check-container {
    display: flex;
    border-radius: 999px;
    background-color: #0969da;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    display: none;
    overflow: hidden;
}
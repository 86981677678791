.epicContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 188px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 188px;
    gap : 112px;
    scrollbar-color: black;
}

.slogunClass{
    font-size : 44px;
    font-weight: 100;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.logoClass{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 16px;
}

.theLinkyoLogo{
    font-weight: 600;
    font-size: 48px;
}

.theLinkyoQuote{
    color: #F5F7F9;
    opacity: 65%;
    font-weight: 400;
    font-size: 24px;
}

.tenXCardClass{
    display: flex;
    flex: 1;
}

.tenXTextBackgroundClass{
    height: 600px;
    /* flex: 1; */
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.tenXText{
    font-size: 200px;
    font-weight: 600;
}

.texXTextEngagement{
    margin-top : -35px;
    font-size: 52px;
    font-weight: 600;
    padding-left: 30px;
}

.overlayCardOne{
    position: absolute;
    left : 0px;
    bottom : 0px;
    opacity : 75%;
}

.overlayCardTwo{
    position: absolute;
    right : 0px;
    top : 0px;
    opacity : 75%;
}

.howItWorksClass{
    display: flex;
    gap : 75px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.howItWorksTitle{
    font-weight: 500;
    font-size: 36px;
}

.joinLinkyoLogo{
    height: 36px;
    width: 36px;
}

.collaborateLogo{
    height: 36px;
    width : 36px;
}

.launchAndTrackLogo{
    height: 36px;
    width: 36px;
}

.howItWorksCardClass{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap : 100px;
    justify-content: center;
    align-items: center;
}

.joinLinkyo{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 380px;
}

.joinLinkyoTitle{
    font-weight: 500;
    font-size: 36px;
}

.joinLinkyoDesc{
    width: 340px;
    justify-content: center;
    font-weight: 200;
    font-size: 20px;
    opacity : 65%;
    color : #F5F7F9;
}

.collaborate{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 380px;
}

.collaborateTitle{
    font-weight: 500;
    font-size: 36px;
}

.collaborateDesc{
    width: 340px;
    justify-content: center;
    font-weight: 200;
    font-size: 20px;
    opacity : 65%;
    color : #F5F7F9;
}

.launchAndTrack{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 380px;
}

.launchAndTrackTitle{
    font-weight: 500;
    font-size: 36px;
}

.launchAndTrackDesc{
    width: 400px;
    justify-content: center;
    font-weight: 200;
    font-size: 20px;
    opacity : 65%;
    color : #F5F7F9;
}